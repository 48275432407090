import React from 'react';
import Helmet from 'react-helmet';

import Footer from '../components/Footer';
import Header from '../components/Header';

import './styles.css';

import { MainStyles } from './styles';

class Template extends React.Component {
	render() {
		const { desc, title, img, children, isIndex } = this.props;

		return (
			<MainStyles>
				<Helmet>
					<html lang="en" />
					<link rel="icon" type="image/png" href="/sophiaunicorn.png" sizes="16x16" />
					<title>{title}</title>
					<meta name="description" content={desc} />
					<meta name="og:title" content={title} />
					<meta name="og:description" content={desc} />
					<meta name="og:image" content={img} />
				</Helmet>
				<Header isIndex={isIndex} />
				{children}
				<Footer />
			</MainStyles>
		);
	}
}

export default Template;
