import React from 'react';
import styled from 'styled-components';

const StyledFooter = styled.footer`
	&.footer {
		${tw`tracking-56 text-black font-round text-center relative `};
		margin-top: -11rem;
		padding-top: 11rem;
		height: 345px;

		.credits {
			${tw`text-16 sm:text-24 relative z-30`};
			a {
				${tw`font-inline text-24 sm:text-36`};
				&:hover {
					border-bottom: 1px solid rgba(0, 0, 0, 0.75);
					padding-bottom: 2px;
				}
			}
		}
	}
`;

const Copyright = styled.div`
	${tw`relative z-20 text-18 sm:text-24 pt-8`};
	span {
		${tw`font-sans text-12 sm:text-14 relative`};
		bottom: 3px;
	}
`;

const Blob = styled.div`
	${tw`z-10`};
	overflow: hidden;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		flex: 0 0 auto;
		width: auto;
	}
`;

const Footer = () => (
	<StyledFooter className="footer">
		<div className="container">
			<div className="credits">
				Built with{' '}
				<a href="https://reactjs.org/" target="_blank" rel="noopener noreferrer">
					react
				</a>
				,{' '}
				<a href="https://www.gatsbyjs.org/" target="_blank" rel="noopener noreferrer">
					gatsby
				</a>{' '}
				and{' '}
				<a href="https://tailwindcss.com/" target="_blank" rel="noopener noreferrer">
					Tailwind
				</a>
				. hosted on{' '}
				<a href="https://www.netlify.com/" target="_blank" rel="noopener noreferrer">
					netlify
				</a>
				. thanks to{' '}
				<a href="https://tylerzey.com/" target="_blank" rel="noopener noreferrer">
					tyler zey
				</a>{' '}
				for{' '}
				<a href="https://graphql.org/" target="_blank" rel="noopener noreferrer">
					graphql
				</a>{' '}
				and backend programming
			</div>
			<Copyright>
				<span>&copy;</span> 2018 Sophia Zey
			</Copyright>
		</div>

		<Blob>
			<svg width="4098px" height="345px" viewBox="0 0 4098 345" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<desc>Created with Sketch.</desc>
				<defs />
				<g id="Mockup" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="background" transform="translate(0.000000, -2926.000000)" fill="#A7E4EB" fillRule="nonzero">
						<path
							d="M1329,3025 C1384.61426,2978.05952 1475,2926 1622,2926 C1913,2926 1991,3045 2212,3045 C2433,3045 2577,2948 2665,2935 C2697,2929 2727,2925 2769,2942 C2811,2959 2908.60815,3014.44477 2958,3040 C3144,3136.23597 3407,3115 3597,3135 C3809.13235,3157.32972 3928,3191 4098,3271 C3854.13235,3271 207,3271 -4.88853402e-12,3271 C66,3125 303.315107,3132.76817 602,3184 C807.987276,3219.3319 906.454339,3200.34148 1016,3184 C1143.64066,3164.9592 1220,3117 1329,3025 Z"
							id="Path-3"
						/>
					</g>
				</g>
			</svg>
		</Blob>
	</StyledFooter>
);

export default Footer;
