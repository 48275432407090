import styled from 'styled-components';

export const MainStyles = styled.div`
	${tw`font-sans`};
	background: #f9f7fa;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	a,
	div,
	p, ul, ol, li, span {
		::selection {
			${tw`bg-secondary-dark text-white`};
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		${tw`font-normal`};
	}

	h1 {
		${tw`font-inline text-64 sm:text-128 text-primary`};
	}
	h2 {
		${tw`font-inline text-32 sm:text-48 text-primary`};
	}
	h3 {
		${tw`font-heavy text-24 sm:text-32 text-secondary-darker tracking-56`};
	}
	.title-big {
		${tw`font-inline text-64 lg:text-128 text-primary`};
	}
	.subtitle,
	h5 {
		${tw`font-round text-20 sm:text-24 tracking-14 text-black`};
	}
	p,
	ul {
		${tw`font-sans text-16 sm:text-18 text-grey-darkest list-reset pb-8 leading-normal`};
	}
	ol {
		${tw`pb-8`};
	}
	ul {
		li {
			${tw`mb-3`};
			&::before {
				${tw`opacity-25 pr-3`};
				content: '';
				display: inline-block;
				width: 0;
				height: 0;
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;
				border-left: 20px solid #9e06b5;
				margin-bottom: 2px;
			}
		}
	}

	.button {
		${tw`font-heavy text-24 sm:text-32 border-2 border-secondary-dark rounded-full pt-2 pb-1 px-6 lg:px-12 text-uppercase tracking-56 text-secondary-darker`};
		display: inline-block;
		transition: ease all 0.2s;
		&:hover {
			${tw`bg-secondary border-secondary text-white`};
		}
	}
	.center-button {
		${tw`w-full text-center`};
	}
	.wrapper {
		${tw`pt-32 sm:pt-12rem pb-20rem`};
	}
	.container {
		${tw`m-auto`};
		max-width: 1200px;
		padding: 0 15px;
		@media (min-width: 576px) {
			padding: 0 30px;
		}
	}

	.row {
		${tw`flex flex-wrap`};
	}
	.centered {
		${tw`flex items-center`};
	}

	.box {
		${tw`bg-white rounded-xl text-center p-4 p-8 pt-0`};
		box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);
		.title-big {
			${tw`relative`};
			margin-top: -0.5em;
		}
	}

	// display
	.d-medium {
		${tw`hidden lg:inline`};
	}

	.neon {
		animation: flicker 2.5s infinite alternate;
		&::-moz-selection {
			color: #fff;
		}
		&::selection {
			color: #fff;
		}
		&:focus {
			outline: none;
		}
	}

	/* Animate neon flicker */
	@keyframes flicker {
		0%,
		23%,
		25%,
		28%,
		30%,
		100% {
			text-shadow: -0.1rem -0.1rem 0.5rem rgba(255, 255, 255, 0.7), 0.1rem 0.1rem 0.5rem rgba(255, 255, 255, 0.7),
				0 0 2rem #d329ed, 0 0 3rem #d329ed, 0 0 5rem #d329ed, 0 0 7rem #d329ed;
		}
		24%,
		29% {
			text-shadow: none;
		}
	}

	iframe {
		${tw`max-w-full`};
	}

	/* contact form */
	// .contact-form {
	// 	${tw`w-full min-h-screen`};
	// 	
	// 	textarea,
	// 	input {
	// 		color: #4e5e72;
	// 		background-color: transparent;
	// 		//	background-image: url("data:image/svg+xml;utf8,\<svg xmlns='http://www.w3.org/2000/svg' width='10' height='24'>\<rect fill='rgb(229, 225, 187)' x='0' y='23' width='10' height='1'/>\</svg>");
	// 		&:focus,
	// 		&:hover {
	// 			outline: none;
	// 			background-color: #fff;
	// 		}
	// 	}
	// 	textarea {
	// 		width: 100%;
	// 		height: 8rem;
	// 		resize: none;

	// 		padding: 0.5rem 0;
	// 		border-radius: 4px;
	// 	}
	// 	input {
	// 		width: 50%;
	// 		margin-bottom: 1rem;
	// 		&[type='text']:invalid,
	// 		[type='email']:invalid {
	// 			box-shadow: none;
	// 			//background-image: url("data:image/svg+xml;utf8,\<svg xmlns='http://www.w3.org/2000/svg' width='10' height='24'>\<rect fill='rgba(240, 132, 114, 0.5)' x='0' y='23' width='10' height='1'/>\</svg>");
	// 		}
	// 	}
	// 	button {
	// 		padding: 0.5rem 1rem;
	// 		border-radius: 0.25rem;
	// 		background-color: rgba(78, 94, 114, 0.9);
	// 		color: white;
	// 		font-size: 1rem;
	// 		transition: background-color 0.2s;
	// 		&:hover,
	// 		:focus {
	// 			outline: none;
	// 			background-color: rgba(78, 94, 114, 1);
	// 		}
	// 	}
	// 	.letter {
	// 		position: absolute;
	// 		left: 0;
	// 		right: 0;
	// 		top: 0;
	// 		width: 30rem;
	// 		margin: auto;
	// 		perspective: 60rem;
	// 	}
	// 	.side {
	// 		height: 12rem;
	// 		background-color: #f9f7fa;
	// 		outline: 1px solid transparent;
	// 		&:nth-of-type(1) {
	// 			padding: 2rem 2rem 0;
	// 			border-radius: 1rem 1rem 0 0;
	// 		}
	// 		&.side:nth-of-type(2) {
	// 			padding: 2rem;
	// 			border-radius: 0 0 1rem 1rem;
	// 			text-align: right;
	// 		}
	// 	}
	// 	.envelope {
	// 		position: absolute;
	// 		left: 0;
	// 		right: 0;
	// 		top: 0;
	// 		margin: auto;
	// 		&.front {
	// 			width: 10rem;
	// 			height: 6rem;
	// 			border-radius: 0 0 1rem 1rem;
	// 			overflow: hidden;
	// 			z-index: 9999;
	// 			opacity: 0;
	// 		}
	// 		&::before,
	// 		&::after {
	// 			position: absolute;
	// 			display: block;
	// 			width: 12rem;
	// 			height: 6rem;
	// 			background-color: #e9dc9d;
	// 			transform: rotate(30deg);
	// 			transform-origin: 0 0;
	// 			box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
	// 			content: '';
	// 		}
	// 		&::after {
	// 			right: 0;
	// 			transform: rotate(-30deg);
	// 			transform-origin: 100% 0;
	// 		}
	// 		&.back {
	// 			top: -4rem;
	// 			width: 10rem;
	// 			height: 10rem;
	// 			overflow: hidden;
	// 			z-index: -9998;
	// 			opacity: 0;
	// 			transform: translateY(-6rem);
	// 			&::before {
	// 				display: block;
	// 				width: 10rem;
	// 				height: 10rem;
	// 				background-color: #e9dc9d;
	// 				border-radius: 1rem;
	// 				content: '';
	// 				transform: scaleY(0.6) rotate(45deg);
	// 			}
	// 		}
	// 	}

	// 	.result-message {
	// 		opacity: 0;
	// 		transition: all 0.3s 2s;
	// 		transform: translateY(9rem);
	// 		z-index: -9999;
	// 	}
	// 	.sent {
	// 		.letter {
	// 			animation: scaleLetter 1s forwards ease-in;
	// 		}
	// 		.side:nth-of-type(1) {
	// 			transform-origin: 0 100%;
	// 			animation: closeLetter 0.66s forwards ease-in;
	// 		}
	// 		.side:nth-of-type(1) h1,
	// 		.side:nth-of-type(1) textarea {
	// 			animation: fadeOutText 0.66s forwards linear;
	// 		}
	// 		button {
	// 			background-color: rgba(78, 94, 114, 0.2);
	// 		}
	// 		.envelope {
	// 			animation: fadeInEnvelope 0.5s 1.33s forwards ease-out;
	// 		}
	// 		.result-message {
	// 			opacity: 1;
	// 			transform: translateY(12rem);
	// 		}
	// 	}
	// 	.centered {
	// 		position: absolute;
	// 		left: 0;
	// 		right: 0;
	// 		margin: 1rem auto;
	// 	}
	// }

	// @keyframes closeLetter {
	// 	50% {
	// 		transform: rotateX(-90deg);
	// 	}
	// 	100% {
	// 		transform: rotateX(-180deg);
	// 	}
	// }
	// @keyframes fadeOutText {
	// 	49% {
	// 		opacity: 1;
	// 	}
	// 	50% {
	// 		opacity: 0;
	// 	}
	// 	100% {
	// 		-webkit-transform: rotate(360deg);
	// 		transform: rotate(360deg);
	// 	}
	// }
	// @keyframes fadeInEnvelope {
	// 	0% {
	// 		opacity: 0;
	// 		transform: translateY(8rem);
	// 	}
	// 	/*90% {opacity: 1; transform: translateY(4rem);}*/
	// 	100% {
	// 		opacity: 1;
	// 		transform: translateY(4.5rem);
	// 	}
	// }
	// @keyframes scaleLetter {
	// 	66% {
	// 		transform: translateY(-8rem) scale(0.5, 0.5);
	// 	}
	// 	75% {
	// 		transform: translateY(-8rem) scale(0.5, 0.5);
	// 	}
	// 	90% {
	// 		transform: translateY(-8rem) scale(0.3, 0.5);
	// 	}
	// 	97% {
	// 		transform: translateY(-8rem) scale(0.33, 0.5);
	// 	}
	// 	100% {
	// 		transform: translateY(-8rem) scale(0.3, 0.5);
	// 	}
	// }
`;
